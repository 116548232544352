body {
  font-family: 'Raleway', sans-serif !important;
  font-weight: bold;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title,
.ant-btn {
  font-weight: bold;
}

#root {
  height: 100%;
}

.dropdown-mm .ant-select-dropdown-menu-item::after {
  content: ' mm';
}

.ant-btn:not(.ant-btn-circle) {
  border-radius: 0;
}

.ant-result-success .ant-result-icon > .anticon {
  color: #cd1619 !important;
}
